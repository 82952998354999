import React, { Component } from 'react';

class CssLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cssLoaded: false, // Track whether CSS is loaded
        };
        this.brand = process.env.BRAND || 'hairlessskin'; // Use brand variable
    }

    componentDidMount() {
        // Dynamically load CSS based on the brand
        if (this.brand === 'hairlessskin') {
            import('../sass/hairlessskin.sass').then(() => {
                this.setState({ cssLoaded: true }); // Mark CSS as loaded
            });
        } else if (this.brand === 'bodycare') {
            import('../sass/bodycare.sass').then(() => {
                this.setState({ cssLoaded: true }); // Mark CSS as loaded
            });
        }
    }

    render() {
        const { cssLoaded } = this.state;

        if (!cssLoaded) {
           return null;
        }

        // Render the children (App component) after CSS is loaded
        return this.props.children;
    }
}

export default CssLoader;