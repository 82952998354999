import hairacless from "../apis/hairacless";
import {SELECT_DATE, FETCH_SLOTS, SELECT_TIME, CONFIRM_TIME, SET_ROOT, FETCH_SETTINGS, SHOW_THANKYOU, SHOW_ERROR} from "./types";


export const setRoot = (root) => dispatch => {
    hairacless.defaults.baseURL = root;
    dispatch({
        type: SET_ROOT,
        payload: root
    });
};

export const fetchSlots = () => dispatch => {
    hairacless.get('/booking/slots').then((response) => {
        dispatch({type: FETCH_SLOTS, payload: response});
    });
};

export const fetchSettings = () => dispatch => {
    hairacless.get('/booking/settings').then((response) => {
        dispatch({type: FETCH_SETTINGS, payload: response});
    });
};

export const saveAppointment = (formValues,confirmedTime) => async(dispatch) => {
    const blacklist = ["spam", "fake", "blocked", "daniel@heim.berlin", "sample@email.tst"]; // Beispiel-Blacklist

    // Überprüfen, ob irgendein Feld des formValues mit der Blacklist übereinstimmt
    const containsBlacklistedWord = Object.values(formValues).some(value =>
        blacklist.some(blacklistedWord => value.toLowerCase().includes(blacklistedWord))
    );

    if (containsBlacklistedWord) {
        // Blacklist-Treffer: Abbruch und Fehler anzeigen
        return dispatch({ type: SHOW_THANKYOU, payload: "Blacklisted content detected" });
    }

    try {
        const response = await hairacless.post('/booking/', { formValues, confirmedTime });

        if (response.status === 201) {
            dispatch({ type: SHOW_THANKYOU });
        } else {
            dispatch({ type: SHOW_ERROR });
        }
    } catch (error) {
        dispatch({ type: SHOW_ERROR });
    }
}

export const selectDate = (day) => {
    return {
        type: SELECT_DATE,
        payload: day,
    }
};

export const selectTime = (slot) => {
    return {
        type: SELECT_TIME,
        payload: slot,
    }
};

export const confirmTime = (slot) => {
    return {
        type: CONFIRM_TIME,
        payload: slot,
    }
};