import {FETCH_SLOTS,SELECT_DATE,SELECT_TIME,CONFIRM_TIME} from "../actions/types";

const INITAL_STATE = {
  slots: [],
  selectedDate: null,
  selectedTime: null,
  confirmedTime: null,
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SLOTS:
            const slots = Array.isArray(action.payload.data) ?
                action.payload.data :
                Object.values(action.payload.data);

            const modifiedSlots = slots.map(item => ({
                ...item,
                slots: Array.isArray(item.slots) ? item.slots : Object.values(item.slots)
            }));

            return {...state, slots: modifiedSlots};

        case SELECT_DATE:
            return {...state, selectedDate: action.payload};
        case SELECT_TIME:
            return {...state, selectedTime: action.payload};
        case CONFIRM_TIME:
            return {...state, confirmedTime: action.payload};
        default:
            return state;
    }
};