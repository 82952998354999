import React, {Component} from "react";
import { connect } from 'react-redux';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faClock, faCalendarCheck, faCalendar } from '@fortawesome/free-solid-svg-icons'


import logoHairlessSkin from '../files/hairlessskin-logo.png';
import logoBodycare from '../files/bodycare-logo.png';

class BookingInfo extends Component {

    constructor(props) {
        super(props);
        this.brand = process.env.BRAND || 'hairlessskin'; // Nutze die brand Variable
    }

    renderSlot()
    {
        if(this.props.confirmedSlot === null) return null;

        const m = moment(this.props.confirmedSlot.datetime);
        return (
            <div className={'slot'}><FontAwesomeIcon icon={faCalendarCheck} size="lg" fixedWidth />{m.format('dddd, DD.MMMM YYYY - HH:mm [Uhr]')}</div>
        );
    }

    renderDate()
    {
        if(this.props.date === null) return null;
        if(this.props.confirmedSlot !== null) return null;

        const m = moment(this.props.date);
        return (
            <div className={'date'}><FontAwesomeIcon icon={faCalendar} size="lg" fixedWidth />{m.format('dddd, DD.MMMM YYYY')}</div>
        );
    }

    renderFooter()
    {
        if(this.props.settings===undefined || this.props.settings===null || this.props.gui.success === true || this.props.gui.error === true) return null;
        return (
            <div className={'info'}>{this.props.settings.cal_cons_info}</div>
        );
    }

    renderHeader()
    {
        if(this.props.settings===undefined || this.props.settings===null) return null;
        return (
            <div>
                <div className={'duration'}><FontAwesomeIcon icon={faClock} size="lg" fixedWidth />{this.props.settings.cal_cons_length} Minuten</div>
                <div className={'location'}><FontAwesomeIcon icon={faMapMarkedAlt} size="lg" fixedWidth />{this.props.settings.org_address_1}
                </div>
            </div>
        );
    }

    renderThanks() {
        if(this.props.gui.success === false) return null;
        return (
            <div className={'booking-message'}>
                <h1>Vielen Dank für Ihr Interesse</h1>
                <p>In den nächsten Minuten erhalten Sie eine Bestätigung Ihres Termins per Mail.</p>
                <p>Einige Tage vor Ihrem Beratungstermin erinnern wir Sie selbstverständlich nochmals per Mail.</p>
                <p>Wir freuen uns auf Ihren Besuch, Ihr Team von {this.props.settings.org_address_1}</p>
            </div>
        );
    }

    renderError() {
        if(this.props.gui.error === false) return null;
        return (
            <div className={'booking-message'}>
                <h1>Das hat leider nicht funktioniert</h1>
                <p>Bitte nehmen Sie Kontakt per Telefon {this.props.settings.org_phone} oder E-Mail {this.props.settings.org_mail} zu uns auf.</p>
                <p>Wir freuen uns auf Ihren Besuch, Ihr Team von {this.props.settings.org_address_1}</p>
            </div>
        );
    }

    render() {
        const logoSrc = this.brand === 'hairlessskin' ? logoHairlessSkin : logoBodycare;
        return (
          <div className={`booking-info ${(this.props.gui.success || this.props.gui.error)?'extended':''}`}>
            <div className={'booking-info__header'}>
                <div className={'logo'}>
                    <img src={logoSrc} alt={`${this.brand === 'hairlessskin' ? 'Hairless Skin' : 'Bodycare'} Logo`} />
                </div>
            </div>
              <div className={'booking-info__content'}>
            <h1>Deine gratis Beratung</h1>

                {this.renderHeader()}
                {this.renderDate()}
                {this.renderSlot()}
            </div>
            <div className={'booking-info__footer'}>
                {this.renderFooter()}
                {this.renderThanks()}
                {this.renderError()}
            </div>
          </div>
        );
    }

}

const mapStateToProps = (state) => {
    //console.log(state);
    return {
        date: state.dateTimeInfo.selectedDate,
        confirmedSlot: state.dateTimeInfo.confirmedTime,
        gui: state.gui,
        settings: state.gui.settings,
    }
};

export default connect(mapStateToProps)(BookingInfo);