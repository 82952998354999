import {
    FETCH_SLOTS,
    FETCH_SETTINGS,
    SELECT_DATE,
    SELECT_TIME,
    CONFIRM_TIME,
    SET_ROOT,
    SHOW_ERROR, SHOW_THANKYOU
} from "../actions/types";

const INITAL_STATE = {
    expanded: false,
    form: false,
    error: false,
    success: false
};

export default (state = INITAL_STATE, action) => {
    switch (action.type) {
        case SET_ROOT:
            return {...state, root: action.payload};
        case FETCH_SLOTS:
            return {...state, expanded: false, form: false, error: false, success: false};
        case FETCH_SETTINGS:
            return {...state, settings: action.payload.data ,expanded: false, form: false, error: false, success: false};
        case SELECT_DATE:
            return {...state, expanded: true, form: false, error: false, success: false};
        case SELECT_TIME:
            return {...state, expanded: true, form: false, error: false, success: false};
        case CONFIRM_TIME:
            return {...state, expanded: false, form: true, error: false, success: false};
        case SHOW_ERROR:
            return {...state, expanded: false, form: false, error: true, success: false};
        case SHOW_THANKYOU:
            return {...state, expanded: false, form: false, error: false, success: true};
        default:
            return state;
    }
};