import React, {Component} from "react";
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import {selectTime, confirmTime} from "../actions";

class SelectTimeItem extends Component {

    render()
    {
        const selected = this.props.slot === this.props.selectedSlot;

        return(
            <div className={`field select-time-item ${selected?'has-addons':''}`}>
                <div className={'control'}>
                    <button className={`button select-time is-info ${!selected?'is-outlined is-expanded':''}`} onClick={() => this.props.selectTime(this.props.slot)}>{this.props.slot.time}</button>
                </div>
                <div className={'control'}>
                    <button className={`button confirm-time is-success ${selected?'is-expanded':''}`} onClick={() => this.props.confirmTime(this.props.slot)}><FontAwesomeIcon icon={faCheckCircle} /></button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        date: state.dateTimeInfo.selectedDate,
        selectedSlot: state.dateTimeInfo.selectedTime,
    }
};

export default connect(mapStateToProps,{selectTime,confirmTime})(SelectTimeItem);