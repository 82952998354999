import React, {Component} from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import SelectTimeItem from "./SelectTimeItem";

class TimePicker extends Component {


    renderHeader() {
        if(this.props.date == null)
        {
            return null;
        }

        return (
            <div className={'TimePicker-Caption'}><div>{moment(this.props.date).format('dddd, DD.MM.YYYY')}</div></div>
        );
    }

    renderTimeslots() {
        if(this.props.date == null)
        {
            return null;
        }

        const day = this.props.slots.filter(slot => slot.date === moment(this.props.date).format('YYYY-MM-DD'));
        if(day.length === 1)
        {
            return day[0].slots.map(slot => {
                return (
                    <SelectTimeItem key={slot.id} slot={slot}/>
                );
            });
        }
    }

    render () {

        // console.log(this.props.className);
        //
        // const className = [this.props.className?.root || ''];
        // if (this.props.className) {
        //     className.concat(this.props.className.split(' '));
        // }
        //
        //
        // console.log(className);

        return (
            <div className='TimePicker'>
                {this.renderHeader()}
                <div className={'time-slots'}>
                    {this.renderTimeslots()}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    //console.log('date', state.dateTimeInfo.selectedDate);
    return {
        slots: state.dateTimeInfo.slots,
        date: state.dateTimeInfo.selectedDate,
    }
};

export default connect(mapStateToProps)(TimePicker);