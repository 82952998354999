import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faEnvelope, faPhoneAlt} from '@fortawesome/free-solid-svg-icons';

import {saveAppointment} from "../actions";

const validate = values => {
    const emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    const errors = {}
    if (!values.phone) {
        errors.phone = 'Bitte Telefonnummer eingeben'
    }
    if (!values.mail) {
        errors.mail = 'Bitte E-Mail eingeben'
    } else if (!emailCheck.test(values.mail)) {
        errors.mail = 'Die eingegeben E-Mail-Adresse ist ungültig'
    }
    if (!values.name) {
        errors.name = 'Bitte Name eingeben'
    }

    return errors
};

class Form extends Component {
    renderError(touched, error)
    {
        if(touched && error)
        {
            return (<p className="help is-danger">{error}</p>);
        }
    }

    renderInput = ({input, label,icon, type, meta: { touched, error, invalid }}) => {
        const className = `input ${touched && invalid?' is-danger':''}`;
        return (
            <div className = "field">
            <label className = "label" > {label} </label>
                <div className="control has-icons-left has-icons-right">
                    <input className={className} autoComplete={'off'} type={type} {...input} />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={icon} />
                    </span>
                    {this.renderError(touched,error)}
                </div>
            </div>
        );
    };

    renderCheckbox({input, label}) {
        return (
            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <input type="checkbox" {...input} />
                           {label}
                    </label>
                </div>
            </div>
        );
    }

    onSubmit = (formContent) => {
        this.props.saveAppointment(formContent,this.props.confirmedTime);
    }

    render() {
        return (
            <div className={'booking-form'}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate={true}>
                    <Field name='name' component={this.renderInput} label={'Name'} icon={faUser} type='text' />
                    <Field name='phone' component={this.renderInput} label={'Telefon'} icon={faPhoneAlt} type='tel'/>
                    <Field name='mail' component={this.renderInput} label={'E-Mail-Adresse'} icon={faEnvelope} type='email' />
                    {/*<Field name='dsgvo' component={this.renderCheckbox} label={'Ich stimme den AGB zu'} />*/}
                    <div className="field">
                        <div className="control">
                            <button className="button is-primary" disabled={this.props.invalid ||this.props.pristine ||this.props.submitting}>Termin buchen</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        confirmedTime: state.dateTimeInfo.confirmedTime,
        gui: state.gui,
    }
};

const formWrapped = reduxForm({
    form: 'customerData',
    validate
})(Form);

export default connect(mapStateToProps, {saveAppointment})(formWrapped);